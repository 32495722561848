html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.boxShadow {
  box-shadow: -3px 3px 5px grey;
}

.headerButton {
  color: #201c1c;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  /* background-color: rosybrown; */
}
.headerButton:hover {
  transition: 0.5s;
  color: #eb4d37;
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
}
.selectedHeaderButton {
  transition: 0.5s;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  color: #eb4d37;
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
}

.aboutText {
  width: 60%;
  font-size: 2vw;
}

.sizemap {
  width: 50%;
  object-fit: contain;
}
@media only screen and (max-width: 614px) {
  .aboutText {
    width: 80%;
    font-size: 2.5vw;
  }

  .sizemap {
    width: 80%;
    object-fit: contain;
  }
}
.font3dot5vw {
  font-size: 3.5vw;
}
.font3vw {
  font-size: 3vw;
}
.font2dot5vw {
  font-size: 2.5vw;
}
.font2vw {
  font-size: 2vw;
}
.font1vw {
  font-size: 1vw;
}
.font0dot5Rem {
  font-size: 0.5rem;
}

.FactoryBox {
  display: flex;
  width: 15%;
  height: 10vw;
  padding: 10px;
  background-color: #ffffff;
  align-items: center;
  border-radius: 15px;
}

.FactoryBox-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.sectionHome {
  min-height: 300px;
  padding-top: 100px;
  display: flex;
  width: 100%;
  align-items: center;
}
.imgSectionHome {
  width: 60%;
}
.sectionAbout {
  height: 600px;
}
.imgSectionAbout {
  width: 20%;
}
.divCarouselCustomer {
  height: 300px;
  width: 100%;
}

.divboxCarouselCustomer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 15.1vw;
}
.dotsCarouselCustomer {
}
.sectionContact {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  background-color: #ec4d37;
}
.ContactText {
  font-size: 1.2vw;
}
.imgSectionContact {
  width: 50px;
}
@media only screen and (max-width: 650px) {
  .sectionHome {
    min-height: 200px;
    padding-top: 80px;
    flex-direction: column;
    text-align: center;
  }
  .imgSectionHome {
    width: 90%;
  }
  .sectionAbout {
    height: 200px;
  }
  .imgSectionAbout {
    width: 40%;
  }
  .sectionContact{
    flex-direction: column;
  }
  .ContactText {
    font-size: 2.5vw;
  }
  .imgSectionContact{
    width: 30px;
  }
  .divCarouselCustomer{
    height: 100px;
  }
  .divboxCarouselCustomer{
    height: 100px;
  }
  .FactoryBox{
    height: 50px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  margin: 0;
  font-family: "Athiti-SemiBold";
}

@font-face {
  font-family: "Athiti-Bold";
  font-style: normal;
  src: url("./assets/font/Athiti-Bold.ttf");
}
@font-face {
  font-family: "Athiti-SemiBold";
  font-style: normal;
  /* font-weight: 400; */
  src: url("./assets/font/Athiti-SemiBold.ttf"); /*IE9 Compat Modes*/
  /* src: local('Open Sans'), local('OpenSans'), */
  /* url('../fonts/open-sans-v13-latin-regular.eot?#iefix') format('embedded-opentype'), IE6-IE8 */
  /* url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'), Super Modern Browsers */
  /* url('../fonts/open-sans-v13-latin-regular.woff') format('woff'), Modern Browsers */
  /* url('../fonts/open-sans-v13-latin-regular.ttf') format('truetype'), Safari, Android, iOS */
  /* url('../fonts/open-sans-v13-latin-regular.svg#OpenSans') format('svg'); Legacy iOS */
}
